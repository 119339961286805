@import 'styles/theme.scss';

.container {
  text-align: center;
  font-size: 20px;
  color: $secondary-main;
  font-weight: $font-weight-500;
  a {
    color: $primary-main;
    margin: 0 5px;
  }
}
