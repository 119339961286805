@import 'styles/theme.scss';

.link {
  color: $font-color-darker;
  font-weight: $font-weight-500;
  font-size: 16px;
  line-height: 24px;
  margin-bottom: 16px;
  text-decoration: none;

  &:hover,
  &:focus {
    color: $secondary-light;
  }

  &.subLink {
    color: $font-color;
    font-weight: $font-weight-400;

    &:hover,
    &:focus {
      color: $secondary-light;
    }
  }
}
