@import 'styles/theme.scss';

.errorPage {
  height: 40vh;
  margin: 0 #{$spacing * 3};
  @media (max-width: $md) {
    margin: 0;
  }
  .container {
    padding-top: 50px;
    margin: 0 auto;
    box-sizing: inherit;
    display: block;
    @media (min-width: $sm) {
      padding: 100px 0;
    }
    .title {
      font-size: 24px;
      font-weight: $font-weight-700;
      line-height: 34px;
      color: $primary-dark;
      text-align: center;
    }
    .subTitle {
      text-align: center;
      font-weight: $font-weight-400;
      font-size: 16px;
      line-height: 24px;
      color: $font-color !important;
      margin-bottom: 0 !important;
    }
  }
}
