@import 'styles/theme.scss';

.nav {
  flex-grow: 1;
  display: flex;
  flex-direction: column;

  @media (min-width: $md) {
    justify-content: center;
    align-items: center;
    flex-direction: row;
    margin: 0 auto;

    &.aside {
      flex-grow: unset;
      margin: 0 0 0 auto;
    }
  }

  .list {
    display: flex;
    flex-direction: column;
    list-style-type: none;
    padding: 0;
    margin: 0;
    width: 100%;

    @media (min-width: $md) {
      justify-content: center;
      align-items: center;
      flex-direction: row;
      gap: 16px;
      width: auto;
    }

    .listItem {
      display: block;
      width: 100%;
      position: relative;
    }
  }
}
