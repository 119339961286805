@import 'styles/theme.scss';

$nav-link-text: $font-color-blue;
$nav-link-text-hover: $secondary-light;
$nav-link-text-current: $primary-dark;
$nav-link-bg-hover: $primary-background3;
$nav-link-bg-focus: $primary-100;

.container {
  display: flex;
  align-items: center;
  border-radius: 4px;

  &:hover {
    color: $nav-link-text-hover;
    background-color: $nav-link-bg-hover;
  }

  .inner {
    flex-grow: 1;
    display: block;
    width: 100%;
    text-overflow: ellipsis;
    text-decoration: none;
    color: $nav-link-text;
    font-size: 16px;
    line-height: 24px;
    font-weight: $font-weight-400;
    letter-spacing: 0.08px;
    padding: 12px 8px;
    margin: 0;
    white-space: nowrap;
    overflow: hidden;

    @media (min-width: $md) {
      max-width: 150px;
    }

    &.link {
      &:hover,
      &.current {
        color: $nav-link-text-hover;
      }

      &:focus {
        color: $nav-link-text-hover;
        background-color: $nav-link-bg-focus;
        outline: none;
      }
    }

    .icon {
      font-size: 18px;
      margin: 0 8px -2px 0;
    }
  }

  .expandButton {
    color: $nav-link-text;
    border: none;
    background: transparent;
    height: 50px;
    padding: 0 8px;
    margin: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 4px;
    cursor: pointer;

    &:hover {
      color: $nav-link-text-hover;
    }
    &:focus {
      color: $nav-link-text-hover;
      background-color: $nav-link-bg-focus;
      outline: none;
    }
    @media (min-width: $md) {
      padding: 0 4px;
    }
  }

  &.open {
    border-bottom: 1px solid $nav-link-text-current;
    border-radius: 4px 4px 0 0;

    .inner {
      color: $nav-link-text-current;
      font-weight: $font-weight-600;

      @media (min-width: $md) {
        font-weight: $font-weight-400;
      }
    }

    .expandButton {
      color: $nav-link-text-current;
    }
  }

  &.aside {
    .inner {
      color: $nav-link-text-current;
      font-weight: $font-weight-600;
    }

    .expandButton {
      color: $nav-link-text-current;
    }
  }
}

.submenu {
  display: block;
  list-style-type: none;
  margin: 0;
  padding: 0;
  width: 100%;
  border-radius: 4px;

  @media (min-width: $md) {
    background: $white;
    position: absolute;
    top: 52px;
    left: 0;
    width: auto;
    min-width: 200px;
    max-width: 350px;
    box-shadow: 0 2px 6px 0 $shadow3, 0 5px 14px 0 $shadow3;
    padding: 8px;

    &.aside {
      left: auto;
      right: 0;
    }
  }

  .submenuItem {
    display: block;
    list-style-type: none;
    margin: 0;
    padding: 0;
    width: 100%;
    background-color: $white;
  }

  .submenuLink {
    display: block;
    width: 100%;
    text-decoration: none;
    color: $nav-link-text;
    font-size: 16px;
    line-height: 24px;
    font-weight: $font-weight-400;
    letter-spacing: 0.08px;
    padding: 12px 8px 12px 32px;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    border-radius: 4px;

    &.current {
      color: $nav-link-text-hover;
    }
    &:hover {
      color: $nav-link-text-hover;
      background-color: $nav-link-bg-hover;
    }
    &:focus {
      color: $nav-link-text-hover;
      background-color: $nav-link-bg-focus;
      outline: none;
    }

    @media (min-width: $md) {
      padding: 12px 8px;
    }
  }

  // logout link
  &.aside {
    .submenuItem {
      &:last-child {
        .submenuLink {
          color: $error-main2;

          &:focus {
            background-color: $primary-51;
          }
        }
      }
    }
  }
}
