@import 'styles/theme.scss';

.backdrop {
  background-color: $shadow1;
  position: absolute;
  width: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1;
  display: none;

  &.visible {
    display: block;
  }
}

.container {
  background: $white;
  min-height: $header-height-sm;
  top: 0;
  position: sticky;
  z-index: 1;

  @media (min-width: $md) {
    display: flex;
    align-items: center;
    min-height: $header-height-md;
    padding: 0;
  }

  .headerInner {
    background: $white;
    min-height: $header-height-sm;
    display: flex;
    align-items: center;
    padding: 0 16px;
    position: relative;
    z-index: 2;

    @media (min-width: $md) {
      min-height: $header-height-md;
      padding: 0;
    }

    .logo {
      width: 122px; // needed for Safari-like browsers
      height: 28px;
      min-height: 28px;

      @media (min-width: $md) {
        width: 42px;
        height: 42px;
        min-height: 42px;
      }
      @media (min-width: 1200px) {
        // 1200 magic value is also used in JSX component, make sure to update in both places
        width: 182px;
      }
    }

    .toggleButton {
      color: $primary-dark;
      margin-left: auto;
      padding: 0;
      border: none;
      background: transparent;
      width: 28px;
      height: 28px;

      svg {
        font-size: 28px; // controls the size of the icon
      }
    }
  }

  .panel {
    background: $white;
    box-shadow: inset 0 2px 2px 0 $shadow2, 0 2px 6px 0 $shadow3, 0 5px 14px 0 $shadow3;
    flex-grow: 1;
    position: absolute;
    width: 100%;
    top: $header-height-sm;
    left: 0;
    right: 0;
    z-index: 1;
    visibility: hidden;

    &.showPanel {
      visibility: visible;
    }

    @media (min-width: $md) {
      box-shadow: none;
      position: relative;
      width: auto;
      top: auto;
      left: auto;
      right: auto;
      z-index: 0;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      padding: 0;
      visibility: visible;
    }

    .panelInner {
      padding: 8px 16px 24px;
      display: flex;
      flex-direction: column;

      @media (min-width: $md) {
        flex-direction: row;
        justify-content: center;
        align-items: center;
        padding: 0;
      }
    }
  }
}
