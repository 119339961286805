@import 'styles/theme.scss';

.container {
  display: inline-block;
  height: 42px;

  @media (min-width: $md) {
    height: 54px;
  }
}
