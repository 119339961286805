@import 'styles/theme.scss';

.container {
  :global {
    .MuiDialog-paper {
      padding: 40px 0;
      min-width: 500px;
    }
    .MuiDialogTitle-root .MuiIconButton-root {
      position: absolute;
      top: 0;
      right: 0;
    }
    .MuiDialogContent-root {
      margin: 0 80px;
      padding-top: 0;
    }
  }

  @media (max-width: $md) {
    br {
      display: none;
    }
    :global {
      .MuiDialog-paper {
        min-width: 300px;
      }
      .MuiDialogContent-root {
        margin: 0 10px;
        padding-top: 0;
      }
    }
  }
}

.title {
  display: block;
  color: $primary-dark;
  font-weight: $font-weight-700;
  font-size: 24px;
  line-height: 30px;
  margin: 0 10px;
  text-align: center;

  @media (max-width: $md) {
    text-align: left;
  }
}
