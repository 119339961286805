@import 'styles/theme.scss';

.container {
  border-top: 1px solid $footer-line;
  padding: 68px 100px;
  margin-top: 80px;
  @media (max-width: $md) {
    padding: 30px 20px;
    margin-top: 30px;
    text-align: center;
  }
}

.logoOuterContainer {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  @media (max-width: $md) {
    order: 2;
    align-items: center;
  }
}

.logoInnerContainer {
  @media (max-width: $md) {
    margin-bottom: $spacing-l;
  }
}

.logoImage {
  border-right: 1px solid $grey3;
  padding-right: $spacing-m;
  margin-right: $spacing-m;

  @media (min-width: $md) {
    height: 38px;
  }
  @media (min-width: $lg) {
    height: 54px;
  }
}

.bcorpImageContainer {
  display: inline-block;
  height: 42px;

  @media (min-width: $md) {
    height: 38px;
  }
  @media (min-width: $lg) {
    height: 54px;
  }
}

.mobilePrivacyAndConditions {
  margin-top: $spacing-m;
  color: $primary-dark;
  padding-left: 5px;

  a {
    color: $primary-dark;
    text-decoration: none;
    font-weight: $font-weight-400;
  }
}

.menuItem {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}

.copyright {
  display: flex;
  flex-direction: column;
  gap: 16px;
  * {
    flex: 0 0 auto;
  }
  @media (min-width: $md) {
    align-content: flex-start;
    flex-direction: row;
    gap: 0.5rem;
  }
  margin-top: 16px;
  font-size: 14px;
  line-height: 20px;
  font-weight: $font-weight-400;
  opacity: 40%;

  .noWrap {
    white-space: nowrap;
  }
}

.socialIcons {
  width: 150px;
  display: flex;
  justify-content: space-between;
  margin-right: 20px;

  @media (max-width: $md) {
    margin: 0 auto;
  }
}

.iconsContainer {
  display: flex;
  justify-content: flex-end;
}

.socialIconLink {
  display: block;
  width: 20px;
  height: 20px;

  &:hover,
  &:focus {
    svg {
      fill: $secondary-light;
    }
  }
}
