@import 'styles/theme.scss';

.actions {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 16px 0 0;

  @media (min-width: $md) {
    padding: 0;
  }

  .signInButton {
    color: $primary-dark;
    background: transparent;
    font-size: 16px;
    line-height: 16px;
    font-weight: $font-weight-500;
    letter-spacing: 0.4px;
    white-space: nowrap;

    &:hover,
    &:focus {
      background: transparent;
    }
  }

  .registerButton {
    color: #fff;
    background: $primary-dark;
    margin-left: 1em;
    font-size: 16px;
    line-height: 16px;
    font-weight: $font-weight-500;
    letter-spacing: 0.4px;
    white-space: nowrap;
  }
}
